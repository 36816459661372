import { createIntl, createIntlCache } from 'react-intl';
import { getLocale } from 'utils/getLocale';
import translations from 'infra/i18n/locales';

const cache = createIntlCache();

const Translator = (data: string): string => {
  const intl = createIntl(
    {
      locale: String(getLocale()),
      messages: translations[getLocale()],
    },
    cache,
  );

  return intl.formatMessage({ id: data });
};

export default Translator;
